import React from "react";
import Button from "./button";

const Servicecard = ({ image, title, to, price, badge, external }) => {
  return (    
      <div className="shadow-md">
        <div className="relative">
          {image}
          {badge && (<span className="absolute bottom-4 left-4 bg-warning text-primary font-bold rounded-lg p-1 px-4 py-3">
            {badge}
          </span>)}
        </div>
        <div className="bg-info text-white px-4 py-4 rounded-b-md">
          <div className="text-2xl font-semibold">{title}</div>
          <div>ab {price}€</div>
          <div className="mt-4 mb-2">
            <Button to={to} external={external} btntype="outlined" />
          </div>
        </div>
      </div>    
  );
};

export default Servicecard;
